<template>
  <div class="text-center nav-drop-down">
    <v-menu left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          depressed
          :ripple="false"
          icon
          x-large
        >
          <slot></slot>
        </v-btn>
      </template>
      <v-list min-width="220px">
        <div v-for="(menuItem, index) in menuItems" :key="`menuItems-${index}`">
          <v-list-item v-if="menuItem.title">
            <v-list-item-title class="ml-1 font-weight-bold ml-2">
              {{ menuItem.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class=""
            v-for="(link, index) in menuItem.links"
            :key="`general-settings-${index}`"
            @click="link.onclick"
          >
            <v-list-item-title
              :class="`ml-8 mr-9 ${link.active ? 'item-menu' : ''}`"
            >
              {{ link.title }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "NavigationMenu",
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  }
};
</script>
<style scoped>
.v-list-item__title.item-menu:before {
  content: "✓";
  width: 1em;
  position: absolute;
  left: 25px;
}
</style>
