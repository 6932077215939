<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <circle class="cls-1" cx="52" cy="52" r="15" stroke-width="0" />
  </svg>
</template>

<script>
export default {
  name: "SlipStitchIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: currentColor;
}
</style>
