<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <circle class="cls-1" :r="r" cx="52" cy="52" stroke-dasharray="12" />
  </svg>
</template>

<script>
export default {
  name: "HoleIcon",
  data() {
    return {
      r: 30
    };
  }
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: none;
}
</style>
