<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.4 210.44">
    <g>
      <polyline
        class="cls-1"
        points="197.2 189.2 197.2 189.2 197.2 91.2 183.2 91.2 183.2 27.99 183.2 27.2 157.2 27.2"
      />
      <polyline
        class="cls-1"
        points="197.2 189.2 197.2 207.24 125.2 207.24 125.2 189.2"
      />
      <path
        class="cls-1"
        d="M95.05,27.2c-63.1,0-27.68,74.06-72.55,74.14h-.04v-.13s26.74,88,26.74,88"
      />
      <polyline
        class="cls-1"
        points="49.2 189.2 49.2 207.24 95.2 207.2 95.2 189.2"
      />
      <polyline class="cls-1" points="211.2 33.2 217.2 27.2 223.2 33.2" />
      <polyline class="cls-1" points="195.2 3.2 201.2 9.2 195.2 15.2" />
      <polyline class="cls-1" points="131.2 15.2 125.2 9.2 131.2 3.2" />
      <polyline class="cls-1" points="28.2 15.2 22.2 9.2 28.2 3.2" />
      <polyline class="cls-1" points="3.2 33.2 9.2 27.2 15.2 33.2" />
      <polyline class="cls-1" points="15.2 201.2 9.2 207.2 3.2 201.2" />
      <polyline class="cls-1" points="88.2 3.2 94.2 9.2 88.2 15.2" />
      <polyline class="cls-1" points="223.2 201.2 217.2 207.2 211.2 201.2" />
      <line class="cls-1" x1="217.2" y1="27.2" x2="217.2" y2="207.2" />
      <line class="cls-1" x1="125.2" y1="9.2" x2="201.2" y2="9.2" />
      <line class="cls-1" x1="22.75" y1="9.2" x2="94.75" y2="9.2" />
      <line class="cls-1" x1="9.2" y1="27.2" x2="9.2" y2="207.2" />
      <line class="cls-1" x1="95.2" y1="177.22" x2="95.2" y2="165.22" />
      <line class="cls-1" x1="95.2" y1="149.22" x2="95.2" y2="137.22" />
      <line class="cls-1" x1="95.2" y1="121.22" x2="95.2" y2="109.22" />
      <line class="cls-1" x1="95.2" y1="93.22" x2="95.2" y2="81.22" />
      <line class="cls-1" x1="125.2" y1="177.22" x2="125.2" y2="165.22" />
      <line class="cls-1" x1="125.2" y1="149.22" x2="125.2" y2="137.22" />
      <path class="cls-1" d="M125.2,63.22v-8c15.7-2.28,26.6-13.55,32-28" />
      <path
        class="cls-1"
        d="M125.2,93.22v-12c20.24-9.09,33.93-29.56,33.93-53.18l.07-.82"
      />
      <line class="cls-1" x1="95.2" y1="65.22" x2="95.2" y2="53.22" />
      <line class="cls-1" x1="95.2" y1="37.22" x2="95.2" y2="27.22" />
      <line class="cls-1" x1="125.2" y1="121.22" x2="125.2" y2="109.22" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PartsIcon"
};
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.4px;
}
</style>
