<template>
  <div @click="handleClick" class="profile">
    {{ getInitials($auth?.user?.name) }}
  </div>
</template>
<script>
export default {
  name: "ProfileIcon",
  methods: {
    getInitials(text) {
      if (!text) return "";
      const initials = text
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase()
        .replace(/[^A-Z]/g, "")
        .substring(0, 2);

      return initials;
    },
    handleClick() {
      if (this.$route.name !== "Dashboard") {
        this.$router.push({ name: "Dashboard" });
      }
    }
  }
};
</script>
<style scoped>
.profile {
  margin-right: 10px;
  background-color: var(--v-primary-base);
  text-align: center;
  border-radius: 50%;
  color: #fff;
  line-height: 32px;
  overflow: hidden;
  height: 32px;
  width: 32px;
  font-size: 16px;
  cursor: pointer;
}
</style>
