<template>
  <v-footer
    color="primary lighten-1"
    padless
    :class="isPreviewMode ? 'disablePointerEvents' : ''"
  >
    <v-row justify="center" no-gutters>
      <v-col
        cols="12"
        class="d-flex flex-row justify-center flex-wrap"
        justify="center"
      >
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          color="white"
          text
          tile
          exact
          :to="link.path"
          class="my-2"
        >
          {{ link.name }}
        </v-btn>
      </v-col>

      <v-col class="py-4 text-center white--text" cols="12">
        <v-container>
          {{ new Date().getFullYear() }} — <strong>ChartCrafter</strong>
          <div class="d-inline p-absolute b-3 r-0 legal-links">
            <v-btn
              v-for="(link, index) in legalLinks"
              :key="index"
              color="white"
              text
              tile
              exact
              :to="link.path"
              class="my-2"
            >
              {{ link.name }}
            </v-btn>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      links: [
        { name: this.$t("navigation.home"), path: "/" },
        { name: this.$t("navigation.designs"), path: "/designs" },
        { name: this.$t("navigation.editor"), path: "/editor" },
        { name: this.$t("navigation.team"), path: "/team" },
        { name: this.$t("navigation.contact"), path: "/contact" }
      ]
    };
  },
  computed: {
    isPreviewMode() {
      return this.$route.name === "Preview";
    },
    legalLinks() {
      const agbLink = {
        name: this.$t("navigation.termsAndConditions"),
        path: "/user-agb"
      };

      const legalLinks = [
        { name: this.$t("navigation.imprint"), path: "/imprint" },
        { name: this.$t("navigation.privacyPolicy"), path: "/privacypolicy" }
      ];

      if (this.$auth.isAuthenticated) legalLinks.push(agbLink);
      return legalLinks;
    }
  }
};
</script>

<style scoped>
.disablePointerEvents {
  pointer-events: none;
}

.legal-links {
  right: 0;
  display: inline;
  position: absolute;
  bottom: 0.9rem;
  right: 0;
}

@media screen and (max-width: 700px) {
  .legal-links {
    display: block;
    position: relative;
    bottom: 0;
  }
}
</style>
