import { requestBackend } from "@/http-common";
import store from "@/store";

class CraftersService {
  constructor() {
    this.path = "crafters";
  }

  async findCurrentUser() {
    let result = null;
    try {
      result = await requestBackend.get(`/${this.path}`);
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  createCrafter(name) {
    return requestBackend.post(`/${this.path}`, { name: name });
  }

  toggleFavorite(cmsId, isFavorite) {
    return requestBackend.post(`/${this.path}/favorites/designs/${cmsId}`, {
      isFavorite: isFavorite
    });
  }

  markBought(cmsId) {
    return requestBackend.post(`/${this.path}/payment/designs/${cmsId}`);
  }

  getFavoriteDesigns() {
    return requestBackend.get(`/${this.path}/favorites/designs`);
  }

  getBoughtDesigns() {
    return requestBackend.get(`/${this.path}/bought/designs`);
  }

  getPurchaseHistory() {
    return requestBackend.get(`/${this.path}/purchases`);
  }

  getInvoicePDF(invoiceId) {
    return requestBackend.get(`/${this.path}/invoices/${invoiceId}`, {
      responseType: "blob"
    });
  }

  async refreshUserData() {
    const user = await this.findCurrentUser();
    store.commit("setCrafter", user.data);
  }
}

export default new CraftersService();
