<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <g class="cls-1">
      <line x1="52" y1="14" x2="52" y2="75" />
      <line x1="21.5" y1="44.5" x2="82.5" y2="44.5" />
      <path
        stroke-linecap="square"
        d="M 52 75 a 12.5 12.5 0 1 0 12.5 12.5"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BackPostHalfDoubleCrochetIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: none;
}
</style>
