<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <ellipse class="cls-1" cx="52" cy="52" :rx="rx" :ry="ry" />
  </svg>
</template>

<script>
export default {
  name: "ChainStitchIcon",
  data() {
    return {
      ratio: 1.5,
      rx: 30
    };
  },
  computed: {
    ry() {
      return this.rx / this.ratio;
    }
  }
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: none;
}
</style>
