<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      @click="$emit('click')"
      plain
      :ripple="false"
      :class="hover ? 'primary--text' : 'transparent'"
    >
      <slot></slot>
    </v-btn>
  </v-hover>
</template>
<script>
export default {
  name: "HeaderLinkButton"
};
</script>
