<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.502"
    height="20.569"
    viewBox="0 0 12.502 20.569"
  >
    <g class="left-arrow">
      <path
        id="Path_62"
        data-name="Path 62"
        d="M-7999.358-14667.33h20.569l-10.548,12.5Z"
        transform="translate(-14654.828 7999.358) rotate(90)"
        fill="#666"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LeftArrowCustom"
};
</script>

<style scoped>
.left-arrow {
  transform: scale(0.7);
}
</style>
