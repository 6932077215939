<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <g class="cls-1">
      <line x1="52" y1="4" x2="52" y2="75" />
      <line x1="27" y1="4" x2="77" y2="4" />
      <g transform="translate(52 30)">
        <line x1="0" y1="-17" x2="0" y2="17" transform="rotate(55)" />
      </g>
      <g transform="translate(52 50)">
        <line x1="0" y1="-17" x2="0" y2="17" transform="rotate(55)" />
      </g>
      <path
        stroke-linecap="square"
        d="M 52 75 a 12.5 12.5 0 1 1 -12.5 12.5"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BackPostTrebleCrochetIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: none;
}
</style>
