// meta
export const WEB_TITLE = "ChartCrafter";
export const WEB_URL =
  typeof window !== "undefined" ? window.location.hostname : "localhost";

export const META_IMAGE = WEB_URL + "/images/Logo CC_reg_color.svg";
export const META_IMAGE_ALT = `Logo of the ${WEB_TITLE}`;
export const META_TWITTER_SITE = "@chartcrafter";

// TINY_MCE
export const VUE_APP_TINY_MCE_API_KEY =
  process.env.VUE_APP_TINY_MCE_API_KEY ||
  "0bhzwnt38k0y7cwsgaqhb66e5pz0npxzqv3f7is8oe47h6aq";

export const DEFAULT_LARGE_IMAGE = "/images/default.webp";
export const DEFAULT_TINY_IMAGE = "/images/default.webp";

export const PAGINATION_SIZE = 8;
