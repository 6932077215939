<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <g class="cls-1">
      <line x1="52" y1="4" x2="52" y2="100" />
      <line x1="22" y1="6" x2="82" y2="6" />
      <g transform="translate(52, 42)">
        <line x1="0" y1="-25" x2="0" y2="25" transform="rotate(55)" />
      </g>
      <g transform="translate(52, 62)">
        <line x1="0" y1="-25" x2="0" y2="25" transform="rotate(55)" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TrebleCrochetIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: none;
}
</style>
