<template>
  <v-app>
    <AppHeader />

    <v-main class="pa-0">
      <router-view />
    </v-main>

    <AppFooter />
  </v-app>
</template>

<script>
import AppHeader from "@/components/layout/header/AppHeader";
import AppFooter from "@/components/layout/AppFooter";
import { WEB_TITLE } from "@/constants/config";
import CraftersService from "@/services/backend/CraftersService";
import i18n from "@/i18n";

export default {
  name: "App",
  metaInfo: {
    title: WEB_TITLE,
    titleTemplate: `${WEB_TITLE} | %s`,
    htmlAttrs: {
      lang: i18n.locale
    }
  },
  async updated() {
    this.$store.commit("setLoggedInState", this.$auth.isAuthenticated);
    if (this.$auth.isAuthenticated && !this.$store.getters.getCrafter) {
      await CraftersService.refreshUserData();
    }
  },
  components: {
    AppFooter,
    AppHeader
  },
  data() {
    return {};
  }
};
</script>

<style>
@import "./assets/main.scss";
</style>
