import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@/auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: () => import("@/pages/landingPage/LandingPage")
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () => import("@/pages/legal/PrivacyPolicyPage")
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("@/pages/about/team/TeamPage")
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: () => import("@/pages/legal/ImprintPage")
  },
  {
    path: "/editor",
    name: "Editor",
    component: () => import("@/pages/about/editor/EditorPage")
  },
  {
    path: "/workshop",
    name: "Workshop",
    component: () =>
      import(/* webpackChunkName: "editor" */ "@/pages/WorkshopPage")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/pages/about/ContactPage")
  },
  {
    path: "/designs",
    name: "Designs",
    component: () => import("@/pages/designs/list/DesignsListPage")
  },
  {
    path: "/preview/:id",
    name: "Preview",
    component: () =>
      import("@/pages/user/designer/designEditor/PreviewDesignPage"),
    beforeEnter: authGuard
  },
  {
    path: "/designs/:id",
    name: "Design",
    component: () => import("@/pages/designs/view/DesignPage")
  },
  {
    path: "/go",
    name: "Go",
    component: () => import("@/pages/designs/RedirectAffiliate")
  },
  {
    path: "/designer-agb",
    name: "DesignerAGB",
    component: () => import("@/pages/legal/AgbPage"),
    props: () => ({
      agbType: "designer"
    })
  },
  {
    path: "/user-agb",
    name: "UserAGB",
    component: () => import("@/pages/legal/AgbPage"),
    props: () => ({
      agbType: "user"
    })
  }
];

routes.push({
  path: "/my-designs",
  name: "MyDesigns",
  component: () => import("@/pages/user/designer/MyDesignsPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/my-charts",
  name: "MyCharts",
  component: () => import("@/pages/user/designer/MyChartsPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/create-design",
  name: "CreateDesign",
  component: () =>
    import("@/pages/user/designer/designEditor/DesignEditorPage"),
  props: () => ({
    startNew: true
  }),
  beforeEnter: authGuard
});

routes.push({
  path: "/edit-design",
  name: "EditDesign",
  component: () =>
    import("@/pages/user/designer/designEditor/DesignEditorPage"),
  props: () => ({
    startNew: false
  }),
  beforeEnter: authGuard
});

routes.push({
  path: "/dashboard",
  name: "Dashboard",
  component: () => import("@/pages/user/DashboardPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/favorite-designs",
  name: "Favorites",
  component: () => import("@/pages/user/crafter/FavoriteDesignsPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/bought-designs",
  name: "Bought",
  component: () => import("@/pages/user/crafter/BoughtDesignsPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/purchase-history",
  name: "Purchases",
  component: () => import("@/pages/user/crafter/PurchaseHistoryPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/login-callback",
  name: "LoginCallback",
  component: () => import("@/pages/user/PostLoginPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/payment-callback",
  name: "PaymentCallback",
  component: () => import("@/pages/user/PostPaymentPage"),
  beforeEnter: authGuard
});

routes.push({
  path: "/:catchAll(.*)*",
  name: "NotFound",
  component: () => import("@/pages/notFound/NotFoundPage")
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  historyApiFallback: true,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

export default router;
