import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import BunnyIcon from "@/components/Icons/BunnyIcon";
import YarnIcon from "@/components/Icons/YarnIcon";
import PartsIcon from "@/components/Icons/PartsIcon";
import MeasurementIcon from "@/components/Icons/MeasurementIcon";
import ClockIcon from "@/components/Icons/ClockIcon";
import ChainStitchIcon from "@/components/Icons/stitches/ChainStitchIcon";
import BackPostDoubleCrochetIcon from "@/components/Icons/stitches/BackPostDoubleCrochetIcon";
import BackPostHalfDoubleCrochetIcon from "@/components/Icons/stitches/BackPostHalfDoubleCrochetIcon";
import BackPostDoubleTrebleCrochetIcon from "@/components/Icons/stitches/BackPostDoubleTrebleCrochetIcon";
import BackPostTrebleCrochetIcon from "@/components/Icons/stitches/BackPostTrebleCrochetIcon";
import BackPostSingleCrochetIcon from "@/components/Icons/stitches/BackPostSingleCrochetIcon";
import FrontPostDoubleCrochetIcon from "@/components/Icons/stitches/FrontPostDoubleCrochetIcon";
import FrontPostHalfDoubleCrochetIcon from "@/components/Icons/stitches/FrontPostHalfDoubleCrochetIcon";
import FrontPostDoubleTrebleCrochetIcon from "@/components/Icons/stitches/FrontPostDoubleTrebleCrochetIcon";
import FrontPostTrebleCrochetIcon from "@/components/Icons/stitches/FrontPostTrebleCrochetIcon";
import FrontPostSingleCrochetIcon from "@/components/Icons/stitches/FrontPostSingleCrochetIcon";
import HoleIcon from "@/components/Icons/stitches/HoleIcon";
import DoubleCrochetIcon from "@/components/Icons/stitches/DoubleCrochetIcon";
import SlipStitchIcon from "@/components/Icons/stitches/SlipStitchIcon";
import SingleCrochetIcon from "@/components/Icons/stitches/SingleCrochetIcon";
import TrebleCrochetIcon from "@/components/Icons/stitches/TrebleCrochetIcon";
import DoubleTrebleCrochetIcon from "@/components/Icons/stitches/DoubleTrebleCrochetIcon";
import HalfDoubleCrochetIcon from "@/components/Icons/stitches/HalfDoubleCrochetIcon";
import MagicRingIcon from "@/components/Icons/stitches/MagicRingIcon";
import TelegramIcon from "@/components/Icons/TelegramIcon";
import RepetitionIcon from "@/components/Icons/stitches/RepetitionIcon";
import LeftArrowCustom from "@/components/Icons/LeftArrowCustom";

Vue.use(Vuetify);

const chartCrafterTheme = {
  primary: "#f69597",
  secondary: "#86bcb8",
  accent: "#fae5e4",
  darkaccent: "#e67778",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
  gray: "#666666",
  black: "#535353"
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: chartCrafterTheme
    }
  },
  icons: {
    values: {
      LeftArrowCustom: {
        component: LeftArrowCustom
      },
      bunny: {
        component: BunnyIcon
      },
      yarn: {
        component: YarnIcon
      },
      parts: {
        component: PartsIcon
      },
      measurement: {
        component: MeasurementIcon
      },
      clock: {
        component: ClockIcon
      },
      chainStitch: {
        component: ChainStitchIcon
      },
      hole: {
        component: HoleIcon
      },
      bpdc: {
        component: BackPostDoubleCrochetIcon
      },
      bphdc: {
        component: BackPostHalfDoubleCrochetIcon
      },
      bpdtr: {
        component: BackPostDoubleTrebleCrochetIcon
      },
      bptr: {
        component: BackPostTrebleCrochetIcon
      },
      bpsc: {
        component: BackPostSingleCrochetIcon
      },
      fpdc: {
        component: FrontPostDoubleCrochetIcon
      },
      fphdc: {
        component: FrontPostHalfDoubleCrochetIcon
      },
      fpdtr: {
        component: FrontPostDoubleTrebleCrochetIcon
      },
      fptr: {
        component: FrontPostTrebleCrochetIcon
      },
      fpsc: {
        component: FrontPostSingleCrochetIcon
      },
      doubleCrochet: {
        component: DoubleCrochetIcon
      },
      slipStitch: {
        component: SlipStitchIcon
      },
      singleCrochet: {
        component: SingleCrochetIcon
      },
      halfDoubleCrochet: {
        component: HalfDoubleCrochetIcon
      },
      trebleCrochet: {
        component: TrebleCrochetIcon
      },
      doubleTrebleCrochet: {
        component: DoubleTrebleCrochetIcon
      },
      magicRing: {
        component: MagicRingIcon
      },
      telegram: {
        component: TelegramIcon
      },
      repetition: {
        component: RepetitionIcon
      }
    }
  }
});
