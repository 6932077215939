<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.2 190.4">
    <g>
      <rect
        class="cls-3"
        x="111.93"
        y="3.65"
        width="103.41"
        height="126.97"
        rx="8"
        ry="8"
      />
      <rect class="cls-3" x="124.49" y="17.42" width="78.28" height="23.31" />
      <rect class="cls-1" x="124.49" y="53.78" width="11.6" height="11.6" />
      <rect class="cls-1" x="191.18" y="53.78" width="11.6" height="11.6" />
      <rect class="cls-1" x="168.95" y="53.78" width="11.6" height="11.6" />
      <rect class="cls-1" x="146.72" y="53.78" width="11.6" height="11.6" />
      <rect class="cls-1" x="124.49" y="78.83" width="11.6" height="11.6" />
      <rect class="cls-1" x="191.18" y="78.83" width="11.6" height="11.6" />
      <rect class="cls-1" x="168.95" y="78.83" width="11.6" height="11.6" />
      <rect class="cls-1" x="146.72" y="78.83" width="11.6" height="11.6" />
      <rect class="cls-1" x="124.49" y="103.87" width="11.6" height="11.6" />
      <rect class="cls-1" x="191.18" y="103.87" width="11.6" height="11.6" />
      <rect class="cls-1" x="168.95" y="103.87" width="11.6" height="11.6" />
      <rect class="cls-1" x="146.72" y="103.87" width="11.6" height="11.6" />
      <path
        class="cls-4"
        d="M75.05,3.2C12.59,3.2,47.65,78.88,3.24,78.97h-.04v-.14s26.46,89.93,26.46,89.93"
      />
      <polyline
        class="cls-4"
        points="29.67 168.76 29.67 187.2 75.2 187.15 75.2 168.76"
      />
      <g>
        <path
          class="cls-1"
          d="M27.11,92.46c-1.21,0-2.16-.42-2.16-1.56,0-.99,.5-1.52,1.6-1.71,2.93-.49,4.18-1.44,5.09-3.19,.91-1.75,1.18-1.94,2.47-1.94,.84,0,1.63,.57,1.63,2.05v23.4c0,1.6-1.18,2.62-2.54,2.62s-2.55-1.02-2.55-2.62v-17.06h-3.54Z"
        />
        <path
          class="cls-1"
          d="M46.95,85.31c.34-.91,1.14-1.67,2.13-1.67s1.82,.53,1.82,1.37c0,.46-.08,.95-.23,1.33l-8.7,24.28c-.34,.91-1.14,1.67-2.13,1.67s-1.82-.53-1.82-1.37c0-.46,.07-.95,.23-1.33l8.7-24.28Z"
        />
        <path
          class="cls-1"
          d="M54.4,111.65c-1.52,0-2.24-.65-2.24-2.17,0-1.97,.91-5.89,5.09-10.07l1.79-1.79c2.66-2.66,3.31-4.03,3.31-6.08s-.84-3.42-2.55-3.42c-1.94,0-2.51,1.48-2.7,3.72-.11,1.52-1.14,2.17-2.32,2.17-1.9,0-2.32-1.52-2.32-2.81,0-4.07,2.66-6.91,7.14-6.91,5.05,0,7.83,2.47,7.83,7.33,0,2.51-1.06,5.05-3.34,7.33l-3.15,3.15c-2.05,2.05-3.04,3.57-3.5,5.21h7.48c1.71,0,2.73,.49,2.73,2.16s-1.02,2.17-2.73,2.17h-10.52Z"
        />
      </g>
      <line class="cls-4" x1="76.95" y1="153.67" x2="76.95" y2="141.67" />
      <line class="cls-4" x1="76.95" y1="125.67" x2="76.95" y2="113.67" />
      <line class="cls-4" x1="76.95" y1="97.67" x2="76.95" y2="85.67" />
      <line class="cls-4" x1="76.95" y1="69.67" x2="76.95" y2="57.67" />
      <line class="cls-4" x1="76.95" y1="41.67" x2="76.95" y2="29.67" />
      <path
        class="cls-3"
        d="M211.45,179.2c1.96,0,3.8-.97,4.89-2.59,3.82-5.68,3.82-13.13,0-18.81-1.09-1.62-2.93-2.59-4.89-2.59h-80.07l-24.18,12,24.18,12h80.07Z"
      />
      <path class="cls-3" d="M131.38,155.2c6.68,6.63,6.68,17.37,0,24" />
      <path
        class="cls-2"
        d="M119.43,161.13c1.62,1.61,2.52,3.8,2.48,6.07-.04,2.27-1,4.43-2.68,5.98"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MeasurementIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke-miterlimit: 10;
}

.cls-1,
.cls-2 {
  fill: currentColor;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  stroke: currentColor;
}

.cls-2,
.cls-3 {
  stroke-width: 6px;
}

.cls-2,
.cls-3,
.cls-4 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-3,
.cls-4 {
  fill: none;
}

.cls-4 {
  stroke-width: 6.4px;
}
</style>
