<template>
  <div :class="isPreviewMode ? 'disablePointerEvents' : ''">
    <v-app-bar
      app
      class="navigation"
      :class="navigationClass"
      :elevation="appBarElevation"
      :color="appBarColor"
      light
      height="auto"
      v-scroll="onScroll"
      ref="appBar"
    >
      <AppLogo class="logo-img" />
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        aria-label="navigation drawer"
        role="navigation"
        @click="openMenu"
      />
      <div class="d-none d-sm-flex">
        <v-hover v-slot="{ hover }" v-for="(link, index) in links" :key="index">
          <div>
            <v-btn
              :to="link.path"
              plain
              :ripple="false"
              :class="hover ? 'primary--text' : 'transparent'"
              v-if="link.active"
            >
              {{ link.name }}
            </v-btn>
          </div>
        </v-hover>
      </div>

      <v-spacer class="d-none d-sm-flex" />
      <div class="d-none d-sm-flex align-center">
        <ProfileIcon v-if="$auth.isAuthenticated" />
        <HeaderMenu :menuItems="generalSettings">
          <v-icon color="primary" class="settings">mdi-cog</v-icon>
        </HeaderMenu>
        <div class="align-center">
          <HeaderLinkButton v-if="!$auth.isAuthenticated" @click="login">
            {{ $t("navigation.login") }}
          </HeaderLinkButton>
          <HeaderLinkButton v-if="$auth.isAuthenticated" @click="logout">
            {{ $t("navigation.logout") }}
          </HeaderLinkButton>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="isMenuOpen" app right temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(link, index) in links"
            :key="index"
            :to="link.path"
          >
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <div>
              <button v-if="!$auth.isAuthenticated" @click="login">
                {{ $t("navigation.login") }}
              </button>
              <button v-if="$auth.isAuthenticated" @click="logout">
                {{ $t("navigation.logout") }}
              </button>
            </div>
          </v-list-item>
          <v-list-item v-if="featureFlag_allowChangeLanguage">
            <LocaleSwitch />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LocaleSwitch from "@/components/layout/header/LocaleSwitch";
import AppLogo from "@/components/layout/header/AppLogo";
import ProfileIcon from "@/components/layout/header/ProfileIcon";
import HeaderLinkButton from "@/components/layout/header/HeaderLinkButton";
import HeaderMenu from "@/components/layout/header/HeaderMenu";
import changeLocale from "@/utils/changeLocale";

const halfTransparentWhite = "rgba(255,255,255,0.6)";

export default {
  name: "HeaderBar",
  components: {
    LocaleSwitch,
    AppLogo,
    ProfileIcon,
    HeaderLinkButton,
    HeaderMenu
  },
  data() {
    return {
      appBarColor: halfTransparentWhite,
      navigationClass: "navigation",
      appBarElevation: 0,
      isMenuOpen: false,
      featureFlag_allowChangeLanguage:
        process.env.VUE_APP_ALLOW_CHANGE_LANGUAGE === "true",
      links: [
        { name: this.$t("navigation.designs"), path: "/designs", active: true },
        { name: this.$t("navigation.editor"), path: "/editor", active: true },
        {
          name: this.$t("navigation.workshop"),
          path: "/workshop",
          active: false
        }
      ]
    };
  },
  computed: {
    isPreviewMode() {
      return this.$route.name === "Preview";
    },
    generalSettings() {
      const showTooltips = JSON.parse(localStorage.getItem("showTooltips"));
      const autoSave = JSON.parse(localStorage.getItem("autoSave"));
      const menuLinks = [
        {
          title: "",
          links: [
            {
              title: this.$t("settings.showTooltips"),
              active: showTooltips,
              onclick: () => this.showTooltips()
            },
            {
              title: this.$t("settings.autoSave"),
              active: autoSave,
              onclick: () => this.autoSave()
            },
            {
              title: this.$t("navigation.faq"),
              active: false,
              onclick: () => this.handleFAQandHelp()
            }
          ]
        }
      ];
      if (this.featureFlag_allowChangeLanguage) {
        const locales = this.$i18n.availableLocales;
        const languageLinks = locales.map((locale) => {
          return {
            title: locale,
            active: locale === this.$i18n.locale,
            onclick: () => this.changeLanguage(locale)
          };
        });
        menuLinks.push({
          title: this.$t("settings.language"),
          links: languageLinks
        });
      }

      return menuLinks;
    }
  },
  setup() {
    return {
      halfTransparentWhite
    };
  },
  methods: {
    onScroll() {
      const appBarHeight = this.$refs.appBar.$el.clientHeight;
      if (
        document.body.scrollTop > appBarHeight ||
        document.documentElement.scrollTop > appBarHeight
      ) {
        this.appBarElevation = 12;
        this.appBarColor = "white";
        this.navigationClass = "navigation-elevated";
      } else {
        this.appBarElevation = 0;
        this.appBarColor = halfTransparentWhite;
        this.navigationClass = "navigation";
      }
    },
    openMenu() {
      this.isMenuOpen = true;
    },
    login() {
      this.$auth.loginWithRedirect({
        ui_locales: this.$i18n.locale
      });
    },
    logout() {
      sessionStorage.clear();
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    handleFAQandHelp() {
      if (this.$route.name !== "FAQ") {
        this.$router.push("/faq");
      }
    },
    changeLanguage(locale) {
      changeLocale(locale);
    },
    showTooltips() {
      const currentShowTooltips = JSON.parse(
        localStorage.getItem("showTooltips")
      );
      this.$store.commit("toggleTooltip");
      this.generalSettings[0].links[0].active = !currentShowTooltips;
      this.$forceUpdate();
    },
    autoSave() {
      const currentAutoSave = JSON.parse(localStorage.getItem("autoSave"));
      localStorage.setItem("autoSave", !currentAutoSave);
      this.generalSettings[0].links[1].active = !currentAutoSave;
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
.settings {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 35px;
  cursor: pointer;
}
.navigation-elevated .v-btn.v-size--default,
.navigation .v-btn.v-size--default {
  font-size: 1.1rem;
}

.navigation,
.navigation-elevated {
  padding: 0.25rem 0rem;
  z-index: 3;
}
.v-navigation-drawer--temporary,
.v-navigation-drawer {
  z-index: 99;
}

.navigation .logo-img {
  width: 6.25rem;
}
.navigation-elevated .logo-img {
  width: 6.25rem;
}

.disablePointerEvents {
  pointer-events: none;
}

@media (max-width: 1264px) {
  .navigation .v-btn.v-size--default,
  .navigation-elevated .v-btn.v-size--default {
    font-size: 1rem;
  }

  .navigation {
    padding: 0.25rem 0rem;
  }
  .navigation-elevated {
    padding: 0.25rem 0rem;
  }

  .navigation .logo-img,
  .navigation-elevated .logo-img {
    width: 6.25rem;
  }
}
@media (max-width: 960px) {
  .v-btn.v-size--default {
    font-size: 1rem;
  }

  .navigation,
  .navigation-elevated {
    padding: 0.25rem 0rem;
  }

  .navigation .logo-img,
  .navigation-elevated .logo-img {
    width: 6.25rem;
  }
}
@media (max-width: 600px) {
  .v-btn.v-size--default {
    font-size: 1rem;
  }

  .navigation,
  .navigation-elevated {
    padding: 0.25rem 0rem;
  }

  .navigation .logo-img,
  .navigation-elevated .logo-img {
    width: 6.25rem;
  }
}
</style>
