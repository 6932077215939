import axios from "axios";
import { useAuth0 } from "./auth";

// Number of ms to keep cache.
const MAX_CACHE_AGE_MS = 60 * 60 * 1000;

const { getTokenSilently } = useAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: () => {}
});

export const requestBackend = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const requestPublicBackend = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + "/public",
  headers: {
    "Content-Type": "application/json"
  }
});

[requestBackend].forEach((instance) => {
  instance.interceptors.request.use(async (config) => {
    try {
      const token = await getTokenSilently();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch {
      /* not logged in */
    }
    return config;
  });
});

export const getCachedData = (key) => {
  const cached = localStorage.getItem(key);
  const whenCached = localStorage.getItem(`${key}:ts`);
  if (cached !== null && whenCached !== null) {
    const age = Date.now() - whenCached;
    if (age < MAX_CACHE_AGE_MS) {
      return JSON.parse(cached);
    } else {
      localStorage.removeItem(key);
      localStorage.removeItem(`${key}:ts`);
    }
  }
  return null;
};
