<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <g class="cls-1">
      <line x1="52" y1="14" x2="52" y2="90" />
      <line x1="14" y1="52" x2="90" y2="52" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SingleCrochetIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke: currentColor;
  stroke-width: 6.4px;
  fill: none;
}
</style>
