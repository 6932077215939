<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.52 226.4">
    <g>
      <path
        class="cls-3"
        d="M14.71,86.45c0,19.35,13.96,35.91,34.06,42.64,6.2,2.08,12.97,2.11,20.05,2.11s13.9-.04,20.12-2.12c20.06-6.75,33.77-23.29,33.77-42.62,0-24.82-22.89-44.43-52.04-45.23"
      />
      <path
        class="cls-3"
        d="M93.97,35.82l10.05-23.09c3.39-7.96,12.59-11.66,20.53-8.27,7.96,3.38,11.67,12.56,8.27,20.53l-12.03,27.76"
      />
      <path
        class="cls-3"
        d="M13.48,44.96L4.82,24.99c-3.4-7.96,.31-17.14,8.27-20.53,7.95-3.39,17.14,.31,20.53,8.27l5.79,13.31"
      />
      <path
        class="cls-3"
        d="M102.8,142.05c7.4-.73,15.29-3.24,21.7-5.12,4.64-1.61,9.7,.83,11.32,5.48,1.61,4.62-.83,9.69-5.47,11.31,0,0-8.94,4.27-18.94,6.15"
      />
      <path
        class="cls-3"
        d="M34.64,140.76c-6.54-1.87-12.87-5.52-17.66-10.08-4.12-2.72-9.6-1.56-12.32,2.56-2.68,4.12-1.56,9.6,2.6,12.28,5.2,3.9,11.32,8.28,19.35,11.62"
      />
      <path
        class="cls-3"
        d="M37.57,57.44c2.7-4.49-.53-10.2-5.76-10.22-2.34-.01-4.57,1.21-5.8,3.27-2.7,4.49,.53,10.2,5.76,10.22,2.34,0,4.57-1.22,5.8-3.27Z"
      />
      <path
        class="cls-3"
        d="M35.27,48.18l5.85-9.75c4.05-6.73,13.91-6.64,17.79,.25,3.88,6.89-1.15,14.54-9,14.52v.02l-11.37-.02"
      />
      <path
        class="cls-3"
        d="M24.71,55.2H13.67c-6.96,0-12.88,7.16-9,14.05,3.88,6.89,13.75,6.98,17.79,.25l5.85-9.75"
      />
      <path
        class="cls-3"
        d="M76.71,193.2c-21.26,0-28-42.11-28-64-.7,3.53-1.68,7.77-3.19,12.66h0c-.04,.12-.07,.24-.11,.35-1.77,5.65-4.16,11.97-7.43,18.48-.06,.12-.12,.24-.19,.36-5.53,10.97-13.53,22.47-25.01,32.2,0,0,1.78,5.11,7.11,11.11,.38,.43,.78,.86,1.19,1.29,4.02,4.22,9.79,8.72,17.88,12.1,7.69,3.22,17.49,5.44,29.89,5.44s22.2-2.22,29.9-5.44c1.23-.51,2.41-1.05,3.53-1.62,6.27-3.12,10.94-6.9,14.35-10.48,.41-.43,.81-.86,1.18-1.29,5.33-6,7.12-11.11,7.12-11.11-11.61-9.84-19.65-21.48-25.2-32.56-.02-.04-.04-.08-.06-.12-3.24-6.47-5.62-12.74-7.37-18.36-.08-.25-.16-.5-.23-.75-1.45-4.73-2.68-8.82-3.36-12.27"
      />
      <path
        class="cls-2"
        d="M109.71,85.2c0-2.76-2.24-5-5-5s-5,2.24-5,5,2.24,5,5,5,5-2.24,5-5Z"
      />
      <circle class="cls-2" cx="33.71" cy="85.2" r="5" />
      <path
        class="cls-3"
        d="M117.82,204.37c2.86,1.24,5.85,1.7,5.85,1.7,4.73,0,9.04,3.84,9.04,8.56s-3.27,8.57-8,8.57c-14.58,0-20.91-5.05-22.43-7.07"
      />
      <path
        class="cls-3"
        d="M35.43,216.13c-1.52,2.02-6.8,7.07-21.38,7.07-4.73,0-9.34-3.84-9.34-8.57s4.61-8.56,9.34-8.56c0,0,2.98-.46,5.85-1.7"
      />
      <path
        class="cls-3"
        d="M53.4,102.95c3.56,8.82,15.45,3.06,15.45-2.54,0,5.59,11.9,11.36,15.46,2.54"
      />
      <path
        class="cls-1"
        d="M64.71,83.39h8c2.21,0,4,1.79,4,4v1.76c0,2.21-1.79,4-4,4h-8c-2.21,0-4-1.79-4-4v-1.76c0-2.21,1.79-4,4-4Z"
      />
      <line class="cls-3" x1="68.71" y1="100.83" x2="68.71" y2="93.57" />
      <line class="cls-3" x1="100.71" y1="179.2" x2="100.71" y2="163.2" />
      <circle class="cls-3" cx="93.41" cy="193.2" r="16" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BunnyIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke-miterlimit: 10;
}

.cls-1,
.cls-2 {
  fill: currentColor;
}

.cls-1,
.cls-2,
.cls-3 {
  stroke: currentColor;
}

.cls-2 {
  stroke-width: 4.8px;
}

.cls-2,
.cls-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-3 {
  fill: none;
  stroke-width: 6.4px;
}
</style>
