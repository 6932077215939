import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const initialShowToolTip = JSON.parse(localStorage.getItem("showTooltips"));

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: {
    app: {
      error: undefined,
      showToolTip: initialShowToolTip,
      crafter: null,
      loggedIn: false
    },
    openDesign: {
      dirty: false,
      design: null,
      activeStep: 0,
      generatedText: "exists"
    },
    steps: [
      { name: "parts", tasks: ["name", "design"] },
      {
        name: "instructions",
        tasks: [
          "cover",
          "teaser",
          "introduction",
          "overview",
          "instructions",
          "images",
          "ending"
        ]
      },
      {
        name: "info",
        tasks: ["material", "categorization"]
      }
    ]
  },
  mutations: {
    setCrafter(state, crafter) {
      state.app.crafter = crafter;
    },
    setDirtyFlag(state, isDirty) {
      state.openDesign.dirty = isDirty;
    },
    setError(state, error) {
      state.app.error = error;
    },
    setLoggedInState(state, isLoggedIn) {
      state.app.loggedIn = isLoggedIn;
    },
    toggleTooltip(state) {
      state.app.showToolTip = !state.app.showToolTip;
      localStorage.setItem("showTooltips", state.app.showToolTip);
    },
    openDesign(state, design) {
      state.openDesign.design = design;
    },
    updateActiveStep(state, step) {
      state.openDesign.activeStep = step;
    }
  },
  getters: {
    isDesigner(state) {
      return (
        !!state.app.crafter && !!state.app.crafter.attributes.designer.data
      );
    },
    getDesigner(state, getters) {
      if (!getters.isDesigner) return null;
      return state.app.crafter.attributes.designer.data;
    },
    getCrafter(state) {
      return state.app.crafter
        ? { ...state.app.crafter.attributes, id: state.app.crafter.id }
        : null;
    },
    isDirty(state) {
      return state.openDesign.dirty;
    },
    partsStepTaskCompletion(state) {
      const design = state.openDesign.design;
      const tasks = state.steps[0].tasks;
      const isNameCompleted = design.properties.name !== "";
      const isDesignCompleted = design.parts.length > 0;
      return {
        [tasks[0]]: isNameCompleted,
        [tasks[1]]: isDesignCompleted
      };
    },
    instructionsStepTaskCompletion(state) {
      const design = state.openDesign.design;
      const tasks = state.steps[1].tasks;

      const isCoverCompleted = design.coverImages.length > 0;
      const isTeaserCompleted =
        design.properties.teaser !== "" &&
        design.properties.teaser.length <= 200;
      const isIntroductionCompleted = design.properties.introduction !== "";
      const isOverviewCompleted = design.overviewImages.length === 3;
      const isInstructionsCompleted = state.openDesign.generatedText !== "";

      let isImagesCompleted = false;
      if (design.parts) {
        isImagesCompleted =
          design.parts.length > 0 &&
          design.parts.every((part) =>
            part.steps.every((step) =>
              step.images ? step.images.length !== 0 : false
            )
          );
      }

      const isEndingCompleted =
        design.ending.text !== "" && design.ending.images.length > 0;

      return {
        [tasks[0]]: isCoverCompleted,
        [tasks[1]]: isTeaserCompleted,
        [tasks[2]]: isIntroductionCompleted,
        [tasks[3]]: isOverviewCompleted,
        [tasks[4]]: isInstructionsCompleted,
        [tasks[5]]: isImagesCompleted,
        [tasks[6]]: isEndingCompleted
      };
    },
    infoStepTaskCompletion(state) {
      const design = state.openDesign.design;
      const tasks = state.steps[2].tasks;

      const isMaterialCompleted =
        design.properties.hookSizes.length > 0 &&
        design.properties.woolText !== "";

      const isCategoryCompleted = design.categorization.categories.length > 0;
      const isStyleCompleted = design.categorization.styles.length > 0;
      const isTypeCompleted = design.categorization.types.length > 0;
      const isCategorizationStepCompleted =
        isCategoryCompleted && isStyleCompleted && isTypeCompleted;

      return {
        [tasks[0]]: isMaterialCompleted,
        [tasks[1]]: isCategorizationStepCompleted
      };
    },
    getTaskCompletionByStep: (state, getters) => (step) => {
      switch (step) {
        case state.steps[0].name:
          return getters.partsStepTaskCompletion;
        case state.steps[1].name:
          return getters.instructionsStepTaskCompletion;
        case state.steps[2].name:
          return getters.infoStepTaskCompletion;
        default:
          return null;
      }
    },
    getAllTaskCompletion: (state, getters) => () => {
      return [
        getters.partsStepTaskCompletion,
        getters.instructionsStepTaskCompletion,
        getters.infoStepTaskCompletion
      ];
    }
  },
  actions: {
    setError({ commit }, error) {
      commit("setError", error);
    }
  }
});
