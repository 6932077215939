<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <g class="cls-1">
      <rect x="2" y="2" width="100" height="100" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "RepetitionIcon"
};
</script>

<style scoped>
.cls-1 {
  stroke: var(--v-secondary-base);
  stroke-width: 6.4px;
  fill: var(--v-secondary-lighten1);
  stroke-dasharray: 10, 10;
}
</style>
