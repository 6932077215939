<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
    <g>
      <path
        class="cls-1"
        d="M52,0C23.33,0,0,23.33,0,52s23.33,52,52,52,52-23.33,52-52S80.67,0,52,0Zm42.52,74.58l-3.92-2.43c-.62,1.19-1.29,2.26-2,3.38l3.91,2.22c-3.79,5.96-8.86,10.98-14.81,14.77l-2.26-3.92c-1.12,.72-2.27,1.39-3.46,2l2.33,3.92c-6.03,3.17-12.94,5.09-19.94,5.43v-7.54c-1,.04-1.48,.06-2.23,.06-.59,0-1.77-.02-1.77-.04v7.54c-8-.27-14.38-2.2-20.54-5.44l2.23-3.92c-1.19-.62-2.36-1.28-3.48-2l-2.27,3.91c-5.96-3.79-11.03-8.86-14.82-14.81l3.91-2.26c-.72-1.12-1.39-2.27-2-3.46l-3.92,2.61c-3.16-6.02-5.09-12.61-5.42-19.61h7.54c-.04-1-.06-1.52-.06-2.29,0-.57,.02-1.71,.04-1.71H4.04c.26-8,2.2-14.69,5.44-20.87l3.92,2.09c.62-1.19,1.28-2.43,2-3.55l-3.92-2.3c3.79-5.96,8.86-11.04,14.81-14.83l2.26,3.9c1.12-.72,2.27-1.39,3.46-2.01l-2.2-3.92c6.16-3.24,12.54-5.17,20.54-5.44v7.54s1.17-.04,1.77-.04c.75,0,1.23,.02,2.23,.06V4.06c7,.33,13.9,2.26,19.94,5.43l-2.29,3.92c1.19,.62,2.32,1.28,3.45,2l2.25-3.92c5.96,3.79,11.02,8.86,14.81,14.81l-3.92,2.26c.72,1.12,1.39,2.27,2,3.46l3.92-1.92c3.24,6.18,5.18,12.87,5.44,20.87h-7.54s.04,1.14,.04,1.71c0,.77-.02,1.29-.06,2.29h7.54c-.34,7-2.26,13.59-5.42,19.61Z"
      />
      <polygon
        class="cls-1"
        points="50.37 50.97 25.37 50.97 25.37 54.97 54.37 54.97 54.37 16.97 50.37 16.97 50.37 50.97"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ClockIcon"
};
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
