<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.4 226.48">
    <g>
      <path
        class="cls-1"
        d="M175.2,129.46c1.14,.38,18.6-12.62,15.67-23.7-.78-2.96-2.91-4.74-5.69-4.74-6.25,0-9.26,7.47-9.98,10.33-.72-2.86-3.73-10.33-9.98-10.33-2.78,0-4.91,1.78-5.69,4.74-2.89,10.93,14.44,24.11,15.67,23.7Z"
      />
      <path
        class="cls-2"
        d="M83.44,87.76c9.68-8.87,15.76-21.71,15.76-35.96,0-26.82-21.49-48.55-47.99-48.55S3.2,24.98,3.2,51.8c0,14.27,6.08,27.09,15.76,35.97,.91,.84,1.86,1.64,2.84,2.41"
      />
      <line class="cls-2" x1="97.57" y1="136.31" x2="97.57" y2="136.3" />
      <path
        class="cls-2"
        d="M92.06,149.21c4.53,7.4,7.14,16.14,7.14,25.48,0,26.82-21.49,48.56-47.99,48.56S3.2,201.5,3.2,174.68c0-3.77,.43-7.45,1.23-10.97"
      />
      <path
        class="cls-2"
        d="M87.5,144.07c-5.41-1.54-26.7-5.16-40.03,28.05,0,0,33.86,18.72,51.73,2.56"
      />
      <path
        class="cls-2"
        d="M18.97,87.77c-9.68,8.88-15.76,21.7-15.76,35.95,0,4.35,.57,8.57,1.63,12.59,1.22,4.6,3.09,8.93,5.51,12.89,5.39,8.85,13.53,15.81,23.2,19.69,2.89,1.16,5.92,2.04,9.07,2.6,1.59,.3,3.21,.51,4.86,.63,0,0,6.44,46.48,29.58,43.46"
      />
      <path
        class="cls-2"
        d="M92.06,149.2l-6.25-7.03c-3.47-3.91-7.28-7.49-11.38-10.71L21.81,90.18"
      />
      <line class="cls-2" x1="97.57" y1="136.3" x2="97.57" y2="136.31" />
      <path
        class="cls-2"
        d="M83.44,87.76c9.68,8.89,15.76,21.71,15.76,35.96,0,4.35-.57,8.56-1.63,12.58"
      />
      <path
        class="cls-2"
        d="M161.45,223.2c-4.37,0-16.83-5.72-22.87-21.43-2.07-5.35-3.38-12.59-3.38-20.57,0-20.94,7.06-35.3,9.57-39.97"
      />
      <path class="cls-2" d="M202.77,141.21c.74,7.34,3.26,39.3-6.95,52.52" />
      <path
        class="cls-2"
        d="M203.85,141.14c3.05,3.91,11.35,15.95,11.35,38.06,0,26.31-11.43,38.47-22.46,42.23h0c-2.66,.92-5.35,1.77-7.9,1.77-1.26,0-2.56,.19-3.89,0-3.29-.47-6.75-2.32-10.04-4.68-6.99-4.95-16.42-14.24-18.19-34.93"
      />
      <path class="cls-2" d="M160.12,141.24c-1.12,1.64-4.44,9.72-7.48,24.68" />
      <path
        class="cls-2"
        d="M187.78,141.61c4.53,25.18-12.75,79.61,4.96,79.83h.09"
      />
      <path class="cls-2" d="M182.2,223.2c-23.83-24.25-15.03-66.96-8-82" />
      <path
        class="cls-2"
        d="M142.56,29.29c-3.63,3.46-9.36,9.82-9.36,21.91,0,19.34,9.08,32,11.58,36"
      />
      <path
        class="cls-2"
        d="M159.54,7.29c-.55,.01-1.05,.04-1.47,.04-3.08,0-13.57,1.7-15.5,21.96-.21,2.07-.31,4.36-.31,6.85,0,23.4,17.68,45.57,22.11,51.06"
      />
      <path
        class="cls-2"
        d="M167.86,64.66h0c0-28.99,13.07-51.93,28.51-51.93,1.18,0,2.32,.07,3.4,.22,9.79,1.31,17.42,8.58,17.42,22.26,0,29.2-10.16,47.02-13,52"
      />
      <path
        class="cls-2"
        d="M190.1,3.95c-1.64-.49-3.48-.75-5.52-.75-7.23,0-12.32,3.37-15.69,8.77-7.55,12.17-6.33,34.67-1.03,52.68h0c2.01,6.83,4.6,12.99,7.54,17.71,1.52,2.45,3.12,3.29,4.78,4.84"
      />
      <path class="cls-2" d="M189.99,87.2c1.99-5.5,13.32-37.33,12.53-60.26" />
      <path class="cls-2" d="M184.48,33.67c2.95,11,4.11,28.89-9.08,48.69" />
      <path class="cls-2" d="M30.4,167.48S.52,177.31,13.4,204.58" />
      <path class="cls-2" d="M42.62,171.49s-24.33,25.11-4.27,49.98" />
      <path
        class="cls-2"
        d="M42.62,106.5c-12.57,2.84-35.12,17.84-22.33,54.35"
      />
      <path class="cls-2" d="M59.83,120.01c-17.22,2.33-30.29,36-20.92,50.66" />
      <path class="cls-2" d="M72.5,129.95s12.44-15.74-3.79-44.65" />
      <path class="cls-2" d="M48.53,111.14c-2.95-7.12-2.38-14.37,1.17-40.34" />
      <path class="cls-2" d="M21.8,90.18s-1.52-25.98,20.81-36.93" />
      <path
        class="cls-2"
        d="M3.57,45.83s15.79-12.25,38.53,.89c0,20.52,15.33,41.04,41.34,41.04"
      />
      <path class="cls-2" d="M42.09,46.72c17.3-22.14,.52-42.7,.52-42.7" />
      <path class="cls-2" d="M89.07,21.98c5.81,11.52-2.49,59.12-29.24,58.82" />
      <path class="cls-2" d="M67.67,6.18c12.81,4.82,4.82,58.02-21.67,58.21" />
      <rect class="cls-2" x="143.2" y="87.24" width="64" height="54" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "YarnIcon"
};
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
  stroke-miterlimit: 10;
}

.cls-1,
.cls-2 {
  stroke: currentColor;
}

.cls-2 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.4px;
}
</style>
